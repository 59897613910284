.contact-section {
  margin-top: 20px;
  background-color: #f5f5f5;
}

.label-element {
    margin: 10px;
    width: 95%;
}

.contact-form {
    /* align-items: center; */
    /* display: block */
    /* padding:4rem; */
  }
  
  @media (min-width: 767px) { 
    .contact-form {
      /* align-items: center; */
      /* display: block */
      padding: 20px;
    }
  }
  
.contact-text-field {
  margin-top: 5px;
  width: 100%;
}

.contact-button-submit {
  background-color: #FF8C00;
  color: #fff;
  width: 100px;
}

.call-us {
  color: #FF8C00;
}

.address-text {
  padding-top: 25px;
  padding-bottom: 10px;
}

.address-list-item {
  margin-bottom: 0;
}

.contact-us-address {
  margin-bottom: 20px;
}

.follow-us-social {}

.socal-icons-invert {
  padding: 10px;
  color: #FF8C00;
}

.socal-icons-invert:hover {
  color: white;;
}