.footer-social-icons {
    padding: 10px;
    /* color: red; */
}

.socal-icons {
    padding: 10px;
    color: white;
}

.socal-icons:hover {
    color: #FF8C00;
}

.footer-copyright-text {
    padding: 10px;
}

.no-list-style {
    list-style: none;
    padding: 0px;
}

.footer-links {
    color: white;
}

.footer {
    background-color: #FF8C00;
}

.footer-copyright {
    /* background-color: #292c2f; */
    /* padding-bottom: 0px; */
}
