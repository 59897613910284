.image-carousel {
    /* height: 500px; */
}

.image-gallery-image {
    height: 500px;
}

@media (max-width: 767px) { 
    .image-gallery-image {
        height: 245px;
    }
}